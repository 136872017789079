<template>
  <div class="w-full min-h-screen bg-gray-50 flex flex-col sm:justify-center items-center pt-6 sm:pt-0">
    <div class="w-full sm:max-w-md p-5 mx-auto">
      <h2 class="mb-12 text-center text-5xl font-extrabold">Mirëseerdhët.</h2>
      <form @submit.prevent="login" @keydown="form.onKeydown($event)">
        <div class="mb-4">
          <label class="block mb-1" for="email">Adresa e emailit</label>
          <input id="email" type="text" v-model="form.email" class="form-input" :class="{ error: form.errors.has('email') }" />
          <div v-if="form.errors.has('email')" class="form-error" v-html="form.errors.get('email')"></div>
        </div>
        <div class="mb-4">
          <label class="block mb-1" for="password">Fjalëkalimi</label>
          <input id="password" type="password" v-model="form.password" class="form-input" :class="{ error: form.errors.has('password') }" />
          <div v-if="form.errors.has('password')" class="form-error" v-html="form.errors.get('password')"></div>
        </div>
        <div class="mt-6 flex items-center justify-between">
          <div class="flex items-center">
            <input id="remember_me" type="checkbox" class="border border-gray-300 text-red-600 shadow-sm focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50" />
            <label for="remember_me" class="ml-2 block text-sm leading-5 text-gray-900">Më kujto</label>
          </div>
          <router-link :to="{name:'ResetPass'}" class="text-sm">Keni harruar fjalëkalimin tuaj? </router-link>
        </div>
        <div class="mt-6">
          <button :disabled="form.busy" class="w-full inline-flex items-center justify-center px-4 py-2 bg-indigo-900 border border-transparent rounded-md font-semibold capitalize text-white hover:bg-indigo-800 active:bg-indigo-800 focus:outline-none focus:border-indigo-800 focus:ring focus:ring-indigo-600 disabled:opacity-25 transition">Hyni</button>
        </div>
        <div class="mt-6 text-center">
          <router-link :to="{name:'Register'}" class="underline">Krijo një llogari</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Form from 'vform'
import axios from 'axios';
import {API_URL} from '@/.env';

export default {
  name:'Login',
  data() {
    return {
      form: new Form({
        email: '',
        password: '',
        remember: false,
      })
    };
  },
  created() {
    this.$store.dispatch('home/hideBasketAndAccount');
  },
  methods: {
    async login () {
      // Submit the form.
      const instance = axios.create({
        baseURL: API_URL
      })

      Form.axios = instance

      const { data } = await this.form.post('/api/login')

      this.$store.dispatch('userName/saveToken', {
        token: data.token,
        remember: this.remember
      })

      // Fetch the user.
      await this.$store.dispatch('userName/fetchUser')

      this.$router.push({ name: 'Account' })
    }
  }
}
</script>

<style></style>
