<template>
  <div class="h-screen w-full flex overflow-hidden">
    <nav v-if="showSidebar" class="flex flex-col bg-gray-200 dark:bg-gray-900 w-64 px-12 pt-4 pb-6">
      <!-- SideNavBar -->

      <div class="flex flex-row border-b items-center justify-between pb-2">
        <!-- Hearder -->
        <span class="text-lg font-semibold capitalize dark:text-gray-300">
			Llogaria ime
			</span>

        <span class="relative ">
				<a
          class="hover:text-green-500 dark-hover:text-green-300
					text-gray-600 dark:text-gray-300"
          href="inbox/">
					<svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round">
						<path
              d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
						<path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
					</svg>
				</a>
				<div
          class="absolute w-2 h-2 rounded-full bg-green-500
					dark-hover:bg-green-300 right-0 mb-5 bottom-0"></div>
			</span>

      </div>

      <div class="mt-8">
        <!-- User info -->
        <img
          v-if="user?.photo_path"
          class="h-12 w-12 rounded-full object-cover"
          :src="user.photo_path"
          alt="enoshima profile"/>
        <h2
          class="mt-4 text-xl dark:text-gray-300 font-extrabold capitalize">
          {{ user?.first_name }} {{ user?.last_name }}
        </h2>
        <span class="text-sm dark:text-gray-300">
				<span class="font-semibold text-green-600 dark:text-green-300">
					{{ user?.account?.name }}<br>
				</span>
			   {{ user?.email }}<br>
		  	</span>
        <span class="text-sm dark:text-gray-300">
				<span class="font-semibold text-green-600 dark:text-green-300">
					{{ user?.account?.phone }}<br>
				</span>
			 		{{ user?.account?.address }}
		  	</span>
      </div>

      <div class="mt-5 flex items-center text-red-700 dark:text-red-400">
        <!-- important action -->
        <a href="#" class="flex items-center" @click.prevent="logout">
          <svg class="fill-current h-5 w-5" viewBox="0 0 24 24">
            <path
              d="M16 17v-3H9v-4h7V7l5 5-5 5M14 2a2 2 0 012
						2v2h-2V4H5v16h9v-2h2v2a2 2 0 01-2 2H5a2 2 0 01-2-2V4a2 2
						0 012-2h9z"></path>
          </svg>
          <span class="ml-2 capitalize font-medium">Dilni</span>
        </a>
      </div>
      <div class="mt-5 flex items-center text-red-700 dark:text-red-400 text-center">
        <!-- important action -->
        <icon @click="showSidebarBlock" name="cheveron-right" class="w-5 h-5 fill-current hover:text-black mr-5 transform rotate-180 md:hidden block"/>
      </div>
    </nav>
    <main
      class="flex-1 flex flex-col bg-gray-100 dark:bg-gray-700 transition
		duration-500 ease-in-out overflow-y-auto">
      <div class="mx-10 my-2">
        <h2 class="my-4 text-4xl font-semibold dark:text-gray-400 flex items-center ">
          <icon @click="showSidebarBlock" name="cheveron-right" class="w-5 h-5 fill-current hover:text-black mr-5"/>
          Lista e porosive
        </h2>
        <!-- component -->
        <div>
          <table class="border-collapse w-full mt-9 rounded-md shadow ">
            <thead>
            <tr>
              <th
                class="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                Id
              </th>
              <th
                class="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                Total
              </th>
              <th
                class="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                Status
              </th>
              <th
                class="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                Data e porosisë
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="order in orders" :key="order.id"
                class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
              <td
                class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
              <span
                class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">ID</span>
                {{ order.id }}
              </td>
              <td
                class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
              <span
                class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">Total</span>
                {{ $filters.currency(order.total) }}
              </td>
              <td
                class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
              <span
                class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">Status</span>
                <span class="rounded py-1 px-3 text-xs font-bold" :class="order.status_class"> {{ order.status }}</span>
              </td>
              <td
                class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
              <span
                class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">Data</span>
                {{ order.order_date }}
              </td>
            </tr>
            <tr v-if="orders.length === 0">
              <td class="border-t px-6 py-4" colspan="4">Nuk ka asnjë porosi.</td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>

    </main>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Icon from '@/components/Icon'
export default {
  name: 'Account',
  components:{
    Icon,
  },
  computed: {
    ...mapGetters({user: 'userName/user'}),
    ...mapGetters('userName',['orders']),
  },
  data(){
    return {
      showSidebar:true
    }
  },
  created() {
    this.$store.dispatch('home/hideBasketAndAccount');
    this.$store.dispatch('userName/fetchUser')
    this.$store.dispatch('userName/fetchOrders')
  },
  methods: {
    showSidebarBlock(){
      this.showSidebar = !this.showSidebar
    },
    async logout() {
      // Log out the user.
      await this.$store.dispatch('userName/logout')

      this.$router.push({name: 'Login'})
    }
  }
}
</script>

<style></style>
